<template>
  <div>
    <div class="wrapper">
      <Loader />
      <SidebarStyle9  />
      <HeaderStyle9 />
      <div class="content-page px-3 margin-top-mediaQ"  >
        <transition name="router-anim">
          <router-view />
        </transition>
      </div>
    </div>
    <FooterStyle />
  </div>
</template>
<script>
import Loader from '@/components/loader/Loader'
import SidebarStyle9 from "@/components/partials/backend/SidebarStyle/SidebarStyle9";
import HeaderStyle9 from "@/components/partials/backend/HeaderStyle/HeaderStyle9";
import FooterStyle from "@/components/partials/backend/FooterStyle/FooterStyle";

export default {
  data() {
    return {
    }
  },
  name: 'Layout-9',
  components: {
    HeaderStyle9,
    FooterStyle,
    SidebarStyle9,
    Loader
  },
  mounted() {
    // document.body.classList='color-light'
    this.darkMode();
  },
  destroyed() {
    document.body.classList = 'iq-saidbar-primary'
    // document.body.classList='color-light'
    this.darkMode();
  },
  methods: {
    darkMode() {
      const isDarkModeActive = JSON.parse(localStorage.getItem("isdark")) == null ? false : JSON.parse(localStorage.getItem("isdark"));
      console.log("localstorage dark mounted layaout ", isDarkModeActive);
      const body = document.querySelector("body");
      if (isDarkModeActive) {
        body.classList.add("dark");
      } else {
        body.classList.remove("dark");
        this.$forceUpdate();
      }
    },
  },
}
</script>
<style>
.margin-top-mediaQ{
  margin-top: -35px
}
@media (max-width: 767px) {
  .margin-top-mediaQ{
  margin-top: 0px
}
}
</style>
